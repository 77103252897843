/** @deprecated - this is moved to libs */
export enum ModalRoutesEnum {
    CookieSettings = 'cookie-instellingen',
    Login = 'login', // gebruiken we alleen in interceptors/guards, is dat nog wenselijk?
    Help = 'help',
    ChangeEmail = 'emailadres-wijzigen', // gebruiken we niet, weg?
    TemporaryPassword = 'tijdelijk-wachtwoord',
    GeneratedAccount = 'gegenereerd-account',
    ForgotPassword = 'wachtwoord-vergeten',
    ForgotEmail = 'emailadres-vergeten',
    PersonalInformation = 'emailadres-persoonsgegevens',
    UnblockAccount = 'account-deblokkeren',
    Question = 'vraag',
    Complaint = 'klacht',
    ResponseTimes = 'reactietermijnen',
    Disclaimer = 'disclaimer',
    SwitchService = 'overstap',
}
